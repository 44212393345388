import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "styles/Global.css";
import { unityContext } from "components/UnityComponent/UnityComponent";

const location = window.location.href;
console.log(location);
var context = unityContext;
console.log(context);

if (
  location === "https://run.atlas.space/" ||
  location === "https://front.atlas.space/"
) {
  window.console.log =
    window.console.debug =
    window.console.info =
    window.console.error =
      function () {
        return false;
      };
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <App />
  </Router>
);
