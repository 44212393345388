import { unityContext } from "components/UnityComponent/UnityComponent";

export interface IUserInformation {
  status: string;
  errors: any;
  message: any;
  data: Data;
}

export interface Data {
  userId: string;
  fullName: string;
  firstName: string;
  surName: string;
  userName: string;
  avatarUrl: string;
  createdDate: string;
  companyId: any;
  companyName: any;
  companyEmployeeTitle: string;
  email: string;
}

export interface IAddAvatarData {
  userInfo: IUserInformation;
  token?: string;
  loginAsAGuest?: boolean;
}

export const unityAddAvatar = (userData: IAddAvatarData | null) => {
  (window as { [key: string]: any })["addAvatar"] = async () => {
    const params = new URLSearchParams(document.location.search);
    let roomName: null | string = params.get("room_name") ? params.get("room_name") : null;
    let sceneName: null | string = params.get("scene_name") ? params.get("scene_name") : null;
    if (userData && userData.userInfo.data) {
      let account: string | null = null;
      // roomName = localStorage.getItem("roomName") ? localStorage.getItem("roomName") : null;

      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
          params: [],
        });
        account = accounts[0];
      }

      console.log(userData.token);

      let data = {
        accessToken: userData.token,
        metamaskAddress: account ? account : null,
        nftRoomName: roomName ? roomName : null,
        sceneName: sceneName,
        roomName: roomName,
        isGuest: false,
        avatarUrl: userData.userInfo.data.avatarUrl,
        username: userData.userInfo.data.userName,
      };

      console.log("dattaaaa", data);

      const dataString = JSON.stringify(data);
      unityContext.send("NetworkManager", "AuthTokenTaken", dataString);

      unityContext.send(
        "NetworkManager",
        "ConnectFromWebGL",
        JSON.stringify({
          url: userData.userInfo.data.avatarUrl,
          userId: userData.userInfo.data.userId,
        })
      );
    } else {
      let data = {
        accessToken: null,
        metamaskAddress: null,
        nftRoomName: roomName ? roomName : null,
        sceneName: sceneName,
        roomName: roomName,
        isGuest: true,
        avatarUrl: null,
        username: null,
      };
      const dataString = JSON.stringify(data);
      unityContext.send("NetworkManager", "AuthTokenTaken", dataString);
    }
    // else if (userData.loginAsAGuest === true) {
    //   let roomName: null | string = null;
    //   roomName = localStorage.getItem("roomName") ? localStorage.getItem("roomName") : null;

    //   let data = {
    //     accessToken: null,
    //     metamaskAddress: null,
    //     nftRoomName: roomName ? roomName : null,
    //     sceneName: sceneName,
    //     roomName: roomName,
    //     isGuest: true,
    //     avatarUrl: null,
    //     username: null,
    //   };

    //   const dataString = JSON.stringify(data);

    //   unityContext.send("NetworkManager", "AuthTokenTaken", dataString);
    // }
  };
};
